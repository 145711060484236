// Color palette: https://coolors.co/094685-03bc96-d5b331-3279a5-d0005d
$color_primary: #094685;
$color_primary_light: #3279a5;
$color_secondary: #03bc96;
$color_highlight: #d5b331;
$color_warn: #d0005d;

$font_size_title: 48px;
$font_size_subtitle: 32px;
$font_size_subsubtitle: 24px;
$font_size_body: 18px;
$font_size_small: 12px;

$font_family_sans_serif: 'Lexend Deca', sans-serif;

$spacing_section: 48px;
$spacing_paragraph: 24px;


@mixin page {
	font-family: 'Lora', serif;
	font-size: $font_size_body;

  .title {
    font-family: $font_family_sans_serif;
    font-size: $font_size_title;
    color: $color_primary;
  }

  .subtitle {
    font-family: $font_family_sans_serif;
    font-size: $font_size_subtitle;
    color: $color_primary;
  }

  .subsubtitle {
    font-family: $font_family_sans_serif;
    font-size: $font_size_subsubtitle;
    color: $color_primary;
  }

  @media only screen and (max-width: 640px) {
    font-size: $font_size_body - 2px;

    .title {
      font-size: $font_size_title - 4px;
    }

    .subtitle {
      font-size: $font_size_subtitle - 4px;
    }
  }
}


@mixin dialog {
	font-family: 'Lora', serif;
	font-size: $font_size_body;

  .p-dialog {
    max-height: 80%;
    overflow: auto;
    margin-top: 24px;
  }

  .p-dialog-title {
    font-family: $font_family_sans_serif;
    font-size: $font_size_subtitle;
    font-weight: initial !important;
    color: $color_primary;
    @media only screen and (max-width: 640px) {
      font-size: $font_size_subtitle - 4px;
    }
  }

  .p-dialog-content {
    font-family: 'Lora', serif;
    font-size: $font_size_body;

    @media only screen and (max-width: 640px) {
      font-size: $font_size_body - 2px;
    }
  }

}


// Basic content/section layout

.content .section {
  margin: $spacing_section;
  margin-left: auto;
  margin-right: auto;
  padding-left: $spacing_section;
  padding-right: $spacing_section;
  max-width: 1080px;

  @media only screen and (max-width: 640px) {
    padding-left: $spacing_section - 12px;
    padding-left: $spacing_section - 12px;
  }
}

.content .section:first-child {
  margin-top: 0;
  padding-top: $spacing_section;
}

.content .section:last-child {
  margin-bottom: 0;
  padding-bottom: $spacing_section;
}

body button.p-button {
  background-color: $color_primary;
  padding: 6px;

  &:enabled:hover {
    background-color: $color_highlight;
  }
}

// Useful helper classes.

.center {
  text-align: center;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.card,
.shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
}


