@import 'styles/common.scss';


.page-home {

	@include page;

  .adjectives {
    margin-bottom: 24px;
  }

  .adjective {
    color: $color_primary;
    display: inline-block;
    font-family: 'Lexend Deca', sans-serif;
    font-size: 20px;
    margin-top: 6px;

    @media only screen and (max-width: 640px) {
      font-size: 16px;
    }
  }

  .adjective:not(:last-child)::after {
    content: " • ";
    white-space: pre;
  }

  .tagline {
    color: $color_primary;
    font-family: 'Lexend Dec', sans-serif;
    font-size: 20px;
    font-style: italic;
    padding: 12px;
  }

  .pics {
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    &.section-wrapper {
      background: $color_primary_light;
    }

    &.section {
      margin-top: 12px;
    }

    .TeaserImage {
      margin: 20px;
    }

  } // .pics

  .cards {
    align-items: stretch;
    .Card {
      height: 100%;
      .card {
        height: 100%;
      }
    }
  }

  .featureName {
    font-family: 'Lexend Deca', sans-serif;
    font-size: 22px;
    margin-bottom: 24px;
  }

  .featureDescription {
  }

  .cta {
    margin-top: $spacing_paragraph;
    text-align: right;
  }

} // .page-home

