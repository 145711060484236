@import 'styles/common.scss';


.Topnav {
  background: $color_primary;
  display: flex;
  height: 48px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);

  .logo {
    height: 29px;
    vertical-align: middle;
    width: 54px;
  }

  & > .left {
    align-items: center;
    display: flex;
    margin-left: 24px;
  }

  & > .right {
    justify-content: flex-end;
    margin-bottom: auto;
    margin-top: auto;
    flex: 1;
    text-align: end;
  }

  .mobile {
    & .sidebarButton {
      background-color: initial;
      border: none;
      margin-right: 24px;
    }

    & a, & a:visited {
      color: $color_primary;
    }

    & a:hover {
      color: $color_primary;
      background-color: #d5b331;
    }
  }

  & .sidebar a {
    display: block;
    text-align: left;
    padding: 8px;
  }

  & a {
    transition: 400ms;
    font-family: $font_family_sans_serif;
    margin-right: 6px;
    padding: 6px 10px;
    text-decoration: none;
  }

  & a, & a:visited {
    color: #eeeeee;
  }

  & a:hover {
    color: #eeeeee;
    background-color: #d5b331;
  }

} // .Topnav
