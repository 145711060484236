@import 'styles/common.scss';


.page-firmwareota {

	@include page;
  @include dialog;

  .image-section-wrapper {
    background: $color_primary_light;

    .image {
      text-align: center;

      img {
        max-width: 100%;
      }
    }
  }

  .cards {
    align-items: stretch;
    .Card {
      height: 100%;
      .card {
        height: 100%;
      }
    }
  }

  .cta {
    margin-top: $spacing_paragraph;
    text-align: right;

    &>* {
      margin-left: $spacing_paragraph;
    }
  }

  .p-dialog-visible {
    width: 90%;
    min-height: 60%;
    max-height: 84%;
    display: flex;
    flex-direction: column;
    transform: translate3d(-50%, -55%, 0) scale(1.01);

    @media only screen and (max-width: 640px) {
      transform: translate3d(-50%, -50%, 0) scale(1.01);
      width: 95%;
      min-height: 90%;
      max-height: 90%;
    }

    .p-dialog-content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }

    .description {
      margin-bottom: $spacing_paragraph;
    }

    .dialogMainContent {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;

      .dialogInnerContent {
        display: flex;
        flex-grow: 1;
      }
    }

    .airtable-embed {
      border: 1px solid #f4f4f4;
      width: 100%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
    }

    .options {
      width: 100%;

      .option {
        background-color: #f4f4f4;
        display: flex;
        flex-direction: column;
        padding: $spacing_paragraph * 0.5;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        height: 100%;

        &:hover {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
        }

        .optionHeader {
          display: flex;
          flex-direction: row;
          margin-bottom: 12px;

          .optionName {
            display: inline-block;
            font-weight: bold;
            font-family: $font_family_sans_serif;
            margin-bottom: 8px;
            flex-grow: 1;
          }

          .freeTrial {
            background-color: $color_secondary;
            border-radius: 8px;
            display: inline-block;
            padding: 4px 16px;
            color: white;
            font-size: 16px;
            position: relative;
            right: -18px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
            text-align: center;
          }
        }

        .bullets {
          flex-grow: 1;
        }

        .bullet {
          list-style: circle;
          font-size: 16px;
          margin: 4px 0;

          &::before {
            content: '• ';
          }
        }
      }

      .highlight {
        background-color: #f1e6bb;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
      }
    }
  }

} // .page-firmwareota

