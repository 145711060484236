@import 'styles/common.scss';


.page-login {

	@include page;

  .login-inputs {
    margin-top: 32px;
    width: 400px;
    max-width: 100%;
    display: flex;
    flex-direction: column;

    & > *:not(:first-child) {
      margin-top: 24px;
    }

    input {
      width: 100%;
    }

    .success {
      color: $color_secondary;
    }

    .failure {
      color: $color_warn;
    }

    .hide {
      display: none;
    }
  }
} // .page-login


