@import 'styles/common.scss';


.page-platform {

	@include page;

  .image-section-wrapper {
    background: $color_primary_light;

    .image {
      text-align: center;

      img {
        max-width: 100%;
      }
    }
  }

  .cards {
    align-items: stretch;
    .Card {
      height: 100%;
      .card {
        height: 100%;
      }
    }
  }

  .cta {
    margin-top: $spacing_paragraph;
    text-align: right;
  }

} // .page-platform

