@import 'styles/common.scss';


.page-about {

	@include page;

  .cta {
    margin-top: $spacing_paragraph;
    text-align: right;

    &>* {
      margin-left: 24px;
      margin-bottom: 24px;
    }
  }

} // .page-about

