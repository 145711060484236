@import 'styles/common.scss';


body {
  background: $color_primary;
}

.backdrop {
  background: white;
  border-left: 4px solid $color_secondary;
  border-right: 4px solid $color_secondary;
}

