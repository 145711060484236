
.desktop {
  display: none;
}

@media only screen and (min-width: 801px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}

