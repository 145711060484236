@import 'styles/common.scss';


.Footer {

  background: #094685;
  color: white;

  .top {
    height: 32px;
    width: 100%;
    box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.4);
  }

  .content {
    display: flex;
    width: 100%;
    padding: $spacing_paragraph;
  }

  .left {
    flex: 1;
  }

  .right {
    justify-content: flex-end;
    margin-bottom: auto;
    margin-top: auto;
    flex: 1;
    text-align: end;
  }

} // .Footer

